<template>
    <div class="card">
      <div class="card-body">
          <div class="row">
              <div class="col-md-4">
                <label>Date</label><br>
                <date-picker v-model="filter.date" value-type="YYYY-MM-DD" :placeholder="filter.date"></date-picker>
              </div>
              <div class="col-md-5">
                 
              </div>
              <div class="col-md-3 float-right">
                  <b-dropdown
                      variant="info"
                      class="mt-2 float-right"
                  >
                      <template #button-content>
                        Download Report
                      </template>
                      <b-dropdown-item @click="downloadReport('excel')">
                        Excel
                      </b-dropdown-item>
                      <b-dropdown-item @click="downloadReport('pdf')">
                        PDF
                      </b-dropdown-item>
                  </b-dropdown>
              </div>
              <div class="col-md-12 mt-1 report">
                  <div v-if="isLoading" class="text-center">
                      <b-spinner
                          class="mb-1"
                          variant="primary"
                      /><br>
                      Loading...
                  </div>
                  <div class="table-responsive" v-if="contentHtml != null" v-html="contentHtml"></div>
              </div>
          </div>
      </div>
    </div>
  </template>
  
<script>
import { BDropdown, BDropdownItem, BSpinner } from 'bootstrap-vue'
import FileSaver from 'file-saver'
import _ from 'lodash'
import axios from "axios";
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    title () {
        return `Settlement Price`
    },
    components: {
        BDropdown,
        BDropdownItem,
        BSpinner,
        DatePicker
    },
    watch: {
        filter: {
            handler: function() {
                if (this.filter.date != '') {
                    this.downloadReport('view')
                }
            },
            deep: true
        }
    },
    mounted() {
        var today = new Date();
        this.filter.date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    },
    setup(props) {
        return {
            checkPermission,
            successNotification,
            errorNotification,
        }
    },
    data() {
        return {
            filter: {
                date: '',
                date: '',
                trader_id: '',
            },
            isLoading: false,
            contentHtml: null,
            requests: [],
            request: null,
        }
    },
    methods: {
        cancel() {
            this.request.cancel();
            this.clearOldRequest("Cancelled");
        },
        logResponseErrors(err) {
            if (axios.isCancel(err)) {
                console.log("Request cancelled");
            }
        },
        clearOldRequest(msg) {
            this.request.msg = msg;
            this.requests.push(this.request);
            this.request = null;
        },
        downloadReport(type = 'excel') {
        var context = this
                context.filter.report_type = type
                if (type == 'view') {
                    context.isLoading = true
                }
                const axiosSource = axios.CancelToken.source();
                this.request = { cancel: axiosSource.cancel, msg: "Loading..." };
                this.$http.get('/admin/report/settlement-price', {
                    responseType: type == 'view' ? '' : 'blob',
                    params: this.filter,
                    cancelToken: axiosSource.token
                }).then(response => {
                    
                    var timestamp = Math.floor(Date.now() / 1000);
                    if (type == 'view') {
                        context.contentHtml = response.data.data.html
                    } else {
                        var timestamp = Math.floor(Date.now() / 1000);
                        if (type == 'excel') {
                            var fileName = 'settlement-price-' + timestamp  + '.xlsx';
                            FileSaver.saveAs(response.data, fileName);
                        }
                        if (type == 'pdf') {
                            var blob = new Blob([response.data], {type: "application/pdf"});
                            var fileName = 'settlement-price-' + timestamp  + '.pdf';
                            FileSaver.saveAs(blob,fileName);
                        }
                    }
                    context.isLoading = false;
                }).catch(error => {
                    context.isLoading = false;
                    if (error.response.data.meta.messages.length > 0) {
                        errorNotification(this, 'Oops!', error.response.data.meta.messages)   
                    }
                })
        }
    },
}
</script>